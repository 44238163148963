<template>
    <div>
        <!-- Title and Logo -->
        <h-retribusi />
        <!-- end Logo and Title -->
        <panel
            title="Surat Pemberitahuan Retribusi Provinsi"
            class="panel panel-success"
        >
            <!-- BAGIAN TOOLS LIST -->
            <b-row>
                <b-col md="12">
                    <b-card border-variant="dark" header-tag="header" footer-tag="footer">
                        <template #header>
                            <div class="pull-right">
                                <b-button class="pull-right" variant="primary" to="/menu/sptrd/pendaftaran"  v-role:any="'Administrator|Maintenance|Bendahara|Operasional|Pimpinan'">
                                    <i class="fa fa-plus pr-1"></i> Tambah SPTRD
                                </b-button>
                            </div>
                            <h6 class="mb-0">Tools</h6>
                        </template>

                        <!-- FILTER -->
                        <b-card-body class="p-0 m-0">
                            <b-row>
                                <b-col md="6">
                                    <b-form-group
                                        :label-cols="3"
                                        label="Filter By"
                                        class="mb-1"
                                    >
                                        <b-input-group size="md">
                                            <b-form-select
                                                v-model="filterBy"
                                                :options="fieldOptions"
                                                @change="changeFilterBy()"
                                            >
                                                <option slot="first" :value="'all'"
                                                    >All Data</option
                                                >
                                            </b-form-select>
                                        </b-input-group>
                                    </b-form-group>
                                    <b-form-group
                                        :label-cols="3"
                                        label="Filter"
                                        class="mb-0"
                                        description="Type to Search or Click Clear to Stop Searching "
                                    >
                                        <b-input-group size="md">
                                            <b-form-input
                                                v-model="filter"
                                                @keyup.enter="testFunc(filter)"
                                                placeholder="Type to Search"
                                                debounce="500"
                                            />
                                            <b-input-group-append>
                                                <b-btn
                                                    :disabled="!filter"
                                                    @click="filter = ''"
                                                    variant="info"
                                                    >Clear</b-btn
                                                >
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        :label-cols="3"
                                        label="Sort"
                                        class="mb-1"
                                    >
                                        <b-input-group size="md">
                                            <b-form-select
                                                v-model="sortBy"
                                                :options="fieldOptions"
                                            >
                                                <option slot="first" :value="null">-- none --</option>
                                            </b-form-select>
                                            <b-form-select
                                                :disabled="!sortBy"
                                                v-model="sortDesc"
                                                slot="append"
                                            >
                                                <option :value="false">Asc</option>
                                                <option :value="true">Desc</option>
                                            </b-form-select>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <hr class="my-2">
                            <b-row>
                                <b-col md="6" v-role:any="'Administrator|Maintenance'">
                                    <b-form-group
                                        :label-cols="3"
                                        label="Perangkat PD"
                                        class="mb-1"
                                    >
                                        <v-select
                                            label="nama"
                                            :reduce="nama => nama.id"
                                            :options="PemerintahDaerah.perangkat"
                                            placeholder="-Pilih Perangkat-"
                                            v-model="perangkatpd"
                                            @input="inputPerangkat"
                                        ></v-select>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" v-role:any="'Administrator|Maintenance'">
                                    <b-form-group
                                        :label-cols="3"
                                        label="Periode SPTRD"
                                        class="mb-1"
                                    >
                                        <b-row>
                                            <b-col md="8">
                                                <v-select
                                                    label="nama"
                                                    :options="bulan"
                                                    :reduce="nama => nama.id"
                                                    placeholder="-Pilih Bulan-"
                                                    v-model="perangkat.bulan"
                                                    @input="inputPerangkat"
                                                ></v-select>
                                            </b-col>
                                            <b-col md="4">
                                                <v-select
                                                    :options="tahunOptions"
                                                    placeholder="-Pilih Tahun-"
                                                    v-model="perangkat.tahun"
                                                    @input="inputPerangkat"
                                                ></v-select>
                                            </b-col>
                                        </b-row>
                                    </b-form-group>
                                </b-col>
                                <b-col md="9">
                                    <b-form-group
                                        :label-cols="2"
                                        label="Status SPTRD"
                                        class="mb-1"
                                    >
                                        <b-form-radio-group
                                            name="radio-sub-component"
                                            v-model="selected"
                                            class="pt-2 pb-0"
                                        >
                                            <b-form-radio @change="filterStatus('all')" value="all">
                                                <span class="font-weight-bold label label-warning">
                                                    Semua Status
                                                </span>
                                            </b-form-radio>
                                            <b-form-radio
                                                v-for="(item,
                                                index) in StatusAplikasi.statusAplikasiOptions"
                                                :key="index"
                                                @change="filterStatus(item.id)"
                                                :value="item.id"
                                            >
                                                <span
                                                    :class="
                                                        `font-weight-bold label label-${item.warna}`
                                                    "
                                                >
                                                    {{ item.nama }}
                                                </span>
                                            </b-form-radio>
                                        </b-form-radio-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <!-- end FILTER -->

                        <!--
                        <div class="pull-right mx-0 my-0">
                            <b-button variant="primary" to="/menu/sptrd/pendaftaran">
                                <i class="fa fa-plus pr-1"></i> Tambah SPTRD
                            </b-button>
                        </div> -->
                        <template #footer>
                            <!-- <em>Footer Slot</em> -->
                            <div class="pull-right">
                                <b-button variant="primary" @click="reload">
                                    <i class="fa fa-sync-alt pr-1"></i> Refresh
                                </b-button>
                                <!-- <b-button class="ml-2" variant="primary" to="/menu/sptrd/pendaftaran">
                                    <i class="fa fa-plus pr-1"></i> Tambah SPTRD
                                </b-button> -->
                            </div>
                        </template>
                    </b-card>
                </b-col>
            </b-row>
            <!-- end BAGIAN TOOLS LIST -->

            <hr class="my-2">

            <!-- Data Table & Pagination -->
            <b-table
                show-empty
                small
                responsive
                id="masTable"
                ref="masTable"
                head-variant="light"
                :hover="true"
                :busy.sync="isBusy"
                :items="myGetData"
                :fields="requiredFields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                bordered
            >
                <template #cell(index)="data">
                    {{ data.index + 1 }}
                </template>
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>

                <template v-slot:table-colgroup="scope">
                    <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{
                            width: field.key === 'ssr_max' ? '125px' :
                                field.key === 'actions' ? '95px' : ''
                        }"
                    />
                </template>

                <template #head()="data">
                    {{ data.label }}
                </template>

                <template #cell(no_spt)="data">
                    {{ data.item.no_spt }}<br>
                    {{ data.item.tgl_spt }}
                </template>

                <template #cell(kode_bayar)="data">
                   <div v-if="data.item.ssr_max">
                    {{ data.item.ssr_max.no_sts }} <br>
                    {{ data.item.ssr_max.dibuat_tgl }}
                   </div>
                </template>

                <template #cell(npwrd)="data">
                    <div v-if="data.item.npwrd">
                        {{ data.item.npwrd.nama }}<br>
                        {{ data.item.npwrd.no_pokok }}
                    </div>
                </template>

                <template #cell(unit_pd)="data">
                    <div v-if="data.item.perangkat_pd">
                        {{ data.item.perangkat_pd.nama }}
                    </div>
                    <div v-if="data.item.unit_pd">
                        {{ data.item.unit_pd.nama }}
                    </div>
                </template>

                <template #cell(masa)="data">
                    {{ data.item.tahun }}/{{ data.item.masa }}
                </template>

                <template #cell(status_spt)="data">
                    <div v-if="data.item.status_spt" style="width: 150px;">
                        <div v-if="data.item.status_spt.id == 1">
                            <div
                                :class="
                                    `label label-${data.item.status_spt.warna}`
                                "
                                :id="`tooltip-${data.item.id}`"
                            >
                                {{ data.item.status_spt.nama }}
                            </div>
                            <b-tooltip
                                :target="`tooltip-${data.item.id}`"
                                variant="secondary"
                                placement="left"
                                triggers="hover"
                            >
                                {{ data.item.status_spt.deskripsi }}
                            </b-tooltip>
                            <br>
                            {{ data.item.dibuat_tgl }}
                        </div>
                        <div v-else-if="data.item.status_spt_id == 2">
                            <div
                                :class="
                                    `label label-${data.item.status_spt.warna}`
                                "
                                :id="`tooltip-${data.item.id}`"
                            >
                                {{ data.item.status_spt.nama }}
                            </div>
                            <b-tooltip
                                :target="`tooltip-${data.item.id}`"
                                variant="secondary"
                                placement="left"
                                triggers="hover"
                            >
                                {{ data.item.status_spt.deskripsi }}
                            </b-tooltip>
                            <br>
                            {{ data.item.ditetapkan_tgl }}
                        </div>
                        <div
                            v-else-if="
                                data.item.status_spt_id == 3 ||
                                data.item.status_spt_id == 4
                            "
                        >
                            <div
                                :class="
                                    `label label-${data.item.status_spt.warna}`
                                "
                                :id="`tooltip-${data.item.id}`"
                            >
                                {{ data.item.status_spt.nama }}
                            </div>
                            <b-tooltip
                                :target="`tooltip-${data.item.id}`"
                                variant="secondary"
                                placement="left"
                                triggers="hover"
                            >
                                {{ data.item.status_spt.deskripsi }}
                            </b-tooltip>
                            <br>
                            {{ data.item.dibayarkan_tgl }}
                        </div>
                        <div v-else-if="data.item.status_spt_id == 5">
                            <div
                                :class="
                                    `label label-${data.item.status_spt.warna}`
                                "
                                :id="`tooltip-${data.item.id}`"
                            >
                                {{ data.item.status_spt.nama }}
                            </div>
                            <b-tooltip
                                :target="`tooltip-${data.item.id}`"
                                variant="secondary"
                                placement="left"
                                triggers="hover"
                            >
                                {{ data.item.status_spt.deskripsi }}
                            </b-tooltip>
                            <br>
                            {{ data.item.dibatalkan_tgl }}
                        </div>
                    </div>
                </template>

                <template #cell(ssr_max)="data">
                    <div v-if="data.item.ssr_max">
                        <div v-if="data.item.ssr_max.status_ssr.id == 6">
                            <div
                                :class="
                                    `label label-${data.item.ssr_max.status_ssr.warna}`
                                "
                                :id="
                                    `tooltip-${data.item.ssr_max.status_ssr.id}`
                                "
                            >
                                {{ data.item.ssr_max.status_ssr.nama }}
                            </div>
                            <b-tooltip
                                :target="
                                    `tooltip-${data.item.ssr_max.status_ssr.id}`
                                "
                                variant="secondary"
                                placement="left"
                                triggers="hover"
                            >
                                {{ data.item.ssr_max.status_ssr.deskripsi }}
                            </b-tooltip>
                            <br>
                            {{ data.item.ssr_max.dibuat_tgl }}
                        </div>
                        <div v-if="data.item.ssr_max.status_ssr.id == 7">
                            <div
                                :class="
                                    `label label-${data.item.ssr_max.status_ssr.warna}`
                                "
                                :id="
                                    `tooltip-${data.item.ssr_max.status_ssr.id}`
                                "
                            >
                                {{ data.item.ssr_max.status_ssr.nama }}
                            </div>
                            <b-tooltip
                                :target="
                                    `tooltip-${data.item.ssr_max.status_ssr.id}`
                                "
                                variant="secondary"
                                placement="left"
                                triggers="hover"
                            >
                                {{ data.item.ssr_max.status_ssr.deskripsi }}
                            </b-tooltip>
                        </div>
                        <div v-if="data.item.ssr_max.status_ssr.id == 8">
                            <div
                                :class="
                                    `label label-${data.item.ssr_max.status_ssr.warna}`
                                "
                                :id="
                                    `tooltip-${data.item.ssr_max.status_ssr.id}`
                                "
                            >
                                {{ data.item.ssr_max.status_ssr.nama }}
                            </div>
                            <b-tooltip
                                :target="
                                    `tooltip-${data.item.ssr_max.status_ssr.id}`
                                "
                                variant="secondary"
                                placement="left"
                                triggers="hover"
                            >
                                {{ data.item.ssr_max.status_ssr.deskripsi }}
                            </b-tooltip>
                            <br>
                            {{ data.item.ssr_max.disetorkan_tgl }}
                        </div>
                        <div v-if="data.item.ssr_max.status_ssr.id == 9">
                            <div
                                :class="
                                    `label label-${data.item.ssr_max.status_ssr.warna}`
                                "
                                :id="
                                    `tooltip-${data.item.ssr_max.status_ssr.id}`
                                "
                            >
                                {{ data.item.ssr_max.status_ssr.nama }}
                            </div>
                            <b-tooltip
                                :target="
                                    `tooltip-${data.item.ssr_max.status_ssr.id}`
                                "
                                variant="secondary"
                                placement="left"
                                triggers="hover"
                            >
                                {{ data.item.ssr_max.status_ssr.deskripsi }}
                            </b-tooltip>
                            <br>
                            {{ data.item.ssr_max.dibatalkan_tgl }}
                        </div>
                    </div>
                </template>

                <template v-slot:cell(actions)="data">
                    <div
                        class="d-flex align-item-center"
                        :style="
                            data.item.status_spt_id === 1
                                ? 'width: 165px'
                                : 'width: 92px'
                        "
                    >
                        <span
                            v-if="
                                data.item.selesai === 0 &&
                                    data.item.status_spt_id !== 5
                            "
                        >
                            <b-button
                                variant="secondary"
                                size="sm"
                                @click="nextButtonTiket(data.item.id)"
                                class="mr-1"
                                v-if="data.item.jenis_form == 'Khusus Tiket Masuk'"
                            >
                                Next <i class="fa fa-arrow-circle-right pl-1"></i>
                            </b-button>
                            <b-button
                                variant="secondary"
                                size="sm"
                                @click="nextButton(data.item.id)"
                                class="mr-1"
                                v-else
                            >
                                Next <i class="fa fa-arrow-circle-right pl-1"></i>
                            </b-button>
                        </span>
                        <span>
                            <b-dropdown
                                split
                                size="sm"
                                variant="info"
                            >
                                <template #button-content>
                                    <i class="fa fa-cogs pr-1"></i> Aksi
                                </template>
                                <b-dropdown-item
                                    @click="showLini(data.item.linimasa)"
                                >
                                    <i class="fa fa-history"></i> Lini Masa
                                </b-dropdown-item>
                                <b-dropdown-item
                                    @click="
                                        showPdf(data.item.id, {
                                            nama: 'skr',
                                            selesai: data.item.selesai
                                        })
                                    "
                                    v-if="data.item.status_spt_id !== 5"
                                    ><i class="fa fa-file-pdf"></i>
                                    SKRD</b-dropdown-item
                                >
                                <b-dropdown-item
                                    @click="
                                        showPdf(data.item.id, {
                                            nama: 'spt',
                                            selesai: data.item.selesai
                                        })
                                    "
                                    v-if="data.item.status_spt_id !== 5"
                                    ><i class="fa fa-file-pdf"></i> Rincian
                                    Biaya Retribusi</b-dropdown-item
                                >
                                <!-- <b-dropdown-item v-if="data.item.jenis_form == 'Khusus Tiket Masuk'">
                                    <i class="fa fa-file-pdf"></i> Tiket Masuk
                                </b-dropdown-item> -->
                                <b-dropdown-divider
                                    v-if="data.item.status_spt_id !== 5"
                                    v-role:any="
                                        'Bendahara|Maintenance|Administrator'
                                    "
                                ></b-dropdown-divider>
                                <b-dropdown-item
                                    @click="validasi(data.item.id)"
                                >
                                    <i class="fa fa-check-square"></i> Validasi Bukti Setor
                                </b-dropdown-item>
                                <b-dropdown-item
                                    @click="batal(data.item)"
                                    v-if="data.item.status_spt_id !== 5"
                                    v-role:any="
                                        'Bendahara|Maintenance|Administrator'
                                    "
                                >
                                    <i class="fa fa-times-circle"></i> Batal
                                </b-dropdown-item>
                            </b-dropdown>
                        </span>
                    </div>
                </template>
            </b-table>
            <!-- end of table -->

            <div class="divider"></div>

            <!-- pagination section -->
            <b-row>
                <div class="divider"></div>
                <b-col md="6" class="my-0">
                    <b-form-group :label-cols="2" label="Per page" class="mb-0">
                        <b-form inline>
                            <b-form-select
                                :options="pageOptions"
                                style="width: 100px;"
                                v-model="perPage"
                            />
                            <label class="ml-1 mr-1">/</label>
                            <b-form-input
                                :disabled="true"
                                v-model="totalRows"
                                style="width: 100px;"
                                class="text-right"
                            />
                            <label class="ml-1">Rows</label>
                        </b-form>
                    </b-form-group>
                </b-col>
                <b-col md="6" class="my-0">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="md"
                        class="my-0 "
                    ></b-pagination>
                </b-col>
            </b-row>
            <!-- end pagination section -->
            <!-- end of Table -->
        </panel>
        <b-modal
            v-model="showLiniMasa"
            id="modalLinaMasa"
            title="Lini Masa SPTRD"
            hide-footer
            no-close-on-backdrop
            size="lg"
        >
            <div>
                <b-form-textarea
                    id="textarea-large"
                    size="lg"
                    v-model="linimasa"
                    placeholder="Lini masa belum tersedia..."
                    rows="8"
                    disabled
                ></b-form-textarea>
            </div>
        </b-modal>
        <b-modal
            v-model="showLoader"
            id="modalLoader"
            hide-footer
            hide-header
            no-close-on-backdrop
            centered
            size="sm"
        >
            <div class="text-center">
                <b-spinner variant="light" label="Text Centered"></b-spinner>
                <h5>Harap Menunggu...</h5>
                <div>Data Anda Sedang Di Proses</div>
            </div>
        </b-modal>

        <!-- Modal Batal -->
        <b-modal
            v-model="showModalBatal"
            id="modal-batal"
            title="Form Pembatalan SPTRD"
            no-close-on-backdrop
            size="lg"
        >
            <div
                class="text-center"
                style="height: 300px; margin-top: 200px;"
                v-if="isLoading"
            >
                <b-spinner variant="primary" label="Spinning"></b-spinner>
                <div>
                    Sedang diproses...
                </div>
            </div>
            <div v-else>
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label">NPWRD: </label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            :class="{
                                'form-control-plaintext': true,
                                'font-weight-bold': true
                            }"
                            v-model="form.npwrd"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label"
                        >Perangkat & Unit:
                    </label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            :class="{
                                'form-control-plaintext': true,
                                'font-weight-bold': true
                            }"
                            v-model="form.perangkat"
                            disabled
                        />
                        <input
                            type="text"
                            :class="{
                                'form-control-plaintext': true,
                                'font-weight-bold': true
                            }"
                            v-model="form.unit"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label">Nomor SPTRD: </label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            :class="{
                                'form-control-plaintext': true,
                                'font-weight-bold': true
                            }"
                            v-model="form.no_spt"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label"
                        >Tanggal SPTRD:
                    </label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            :class="{
                                'form-control-plaintext': true,
                                'font-weight-bold': true
                            }"
                            v-model="form.tgl_spt"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label"
                        >Status Permohonan:
                    </label>
                    <div class="col-md-8" style="font-size: 14px;">
                        <div
                            :class="`my-2 label label-${form.status_spt.warna}`"
                            v-if="form.status_spt_id == 1"
                        >
                            {{ form.status_nama }}
                        </div>
                        <div
                            :class="`my-2 label label-${form.status_spt.warna}`"
                            v-else-if="form.status_spt_id == 2"
                        >
                            {{ form.status_nama }}
                        </div>
                        <div
                            :class="`my-2 label label-${form.status_spt.warna}`"
                            v-else-if="form.status_spt_id == 3"
                        >
                            {{ form.status_nama }}
                        </div>
                        <div
                            :class="`my-2 label label-${form.status_spt.warna}`"
                            v-else-if="form.status_spt_id == 4"
                        >
                            {{ form.status_nama }}
                        </div>
                        <div
                            :class="`my-2 label label-${form.status_spt.warna}`"
                            v-else-if="form.status_spt_id == 5"
                        >
                            {{ form.status_nama }}
                        </div>
                    </div>
                </div>
                <hr />
                <div class="form-group row m-3">
                    <label class="col-form-label col-md-3">Lini Masa: </label>
                    <div class="col-md-9">
                        <b-form-textarea
                            id="textarea-large"
                            size="lg"
                            placeholder="Masukkan alasan pembatalan"
                            rows="6"
                            v-model="form.linimasa"
                            disabled
                        ></b-form-textarea>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-form-label col-md-3">Keterangan: </label>
                    <div class="col-md-9">
                        <b-form-textarea
                            id="textarea-large"
                            size="lg"
                            placeholder="Masukkan alasan pembatalan"
                            rows="3"
                            v-model="keterangan"
                        ></b-form-textarea>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-form-label col-md-3"
                        >Ganti Status:
                    </label>
                    <div class="col-md-9">
                        <b-button
                            variant="outline-danger"
                            @click="pembatalan(form.id)"
                            :disabled="isDisabled"
                        >
                            <i class="fa fa-times-circle"></i> Batalkan
                        </b-button>
                    </div>
                </div>
            </div>
            <template #modal-footer>
                <div class="w-100">
                    <div class="float-left">
                        <b-button
                            variant="outline-secondary"
                            @click="showModalBatal = false"
                            :disabled="isLoading"
                        >
                            <i class="fa fa-arrow-left"></i> Kembali
                        </b-button>
                    </div>
                </div>
            </template>
        </b-modal>
        <b-modal
            v-model="showLoader"
            id="modalLoader"
            hide-footer
            hide-header
            no-close-on-backdrop
            centered
            size="sm"
        >
            <div class="text-center">
                <b-spinner variant="light" label="Text Centered"></b-spinner>
                <h5>Harap Menunggu...</h5>
                <div>Data Anda Sedang Di Proses</div>
            </div>
        </b-modal>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import HRetribusi from "../../../components/npwr/HRetribusi.vue";
import axios from "axios";
import hapusStorage from "@/helper/hapusLocalStore";
import { mapState } from "vuex";

export default {
    mixins: [hapusStorage],
    components: { HRetribusi },
    data() {
        return {
            selected: "all",
            // Tools
            isBusy: false,
            sortBy: null,
            sortDesc: false,
            filter: null,
            filterBy: "all",
            pageOptions: [5, 10, 15, 25],
            perPage: 10,
            currentPage: 1,
            totalRows: 0,
            statusspt: "all",
            perangkatpd: "all",

            // fields
            fields: [
                {
                    key: "index",
                    label: "No.",
                    tdClass: "text-right"
                },
                {
                    key: "npwrd",
                    label: "NPWRD",
                    sortable: true
                },
                {
                    key: "unit_pd",
                    label: "Perangkat & Unit"
                },
                {
                    key: "no_spt",
                    label: "No. / Tgl. SPTRD",
                    sortable: true
                },
                {
                    key: "kode_bayar",
                    label: "No. / Tgl. KODE BAYAR",
                    sortable: true
                },
                {
                    key: "status_spt",
                    label: "Status SPT",
                    sortable: false
                },
                {
                    key: "ssr_max",
                    label: "Status Pemindah Bukuan",
                    sortable: false
                },
                {
                    key: "actions",
                    label: "Opsi"
                }
            ],
            showLoader: false,
            isLoading: false,
            pdfUrl: "",
            namaDokumen: "",
            showLiniMasa: false,
            showModalBatal: false,
            linimasa: "",
            form: new Form({
                npwrd: "",
                perangkat: "",
                unit: "",
                no_spt: "",
                tgl_spt: "",
                status_spt_id: "",
                status_nama: "",
                linimasa: "",
                id: "",
                keterangan: "",
                status_spt: ""
            }),
            keterangan: "",
            isDisabled: false,
            bulan: [
                {
                    id: "01",
                    nama: "Januari"
                },
                {
                    id: "02",
                    nama: "Februari"
                },
                {
                    id: "03",
                    nama: "Maret"
                },
                {
                    id: "04",
                    nama: "April"
                },
                {
                    id: "05",
                    nama: "Mei"
                },
                {
                    id: "06",
                    nama: "Juni"
                },
                {
                    id: "07",
                    nama: "Juli"
                },
                {
                    id: "08",
                    nama: "Agustus"
                },
                {
                    id: "09",
                    nama: "September"
                },
                {
                    id: "10",
                    nama: "Oktober"
                },
                {
                    id: "11",
                    nama: "November"
                },
                {
                    id: "12",
                    nama: "Desember"
                }
            ],
            tahunOptions: [],
            showLoader: false,
            role: JSON.parse(localStorage.getItem('roles')),
            perangkat: {
                bulan: null,
                tahun: null
            }
        };
    },
    computed: {
        fieldOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        },
        requiredFields: function () {
            let requiredField = []
            if (['Wajib Retribusi'].includes(this.role[0])) {
                requiredField = this.fields.filter(value => value.key !== 'actions')
            } else {
                requiredField = this.fields
            }
            return requiredField
        },
        ...mapState(["StatusAplikasi", "PemerintahDaerah"])
    },
    mounted() {
        this.$store.dispatch("StatusAplikasi/dataStatusAplikasi");
        this.$store.dispatch("PemerintahDaerah/getPerangkat");
        this.getTahun();
    },
    methods: {
        // data table
        myGetData(ctx) {
            // ctx =
            // berasal dari tag <b-table></b-table>
            //this.$Progress.start();
            this.isBusy = true;
            let promise = axios.get("/api/transaksi/spt", {
                params: {
                    page: ctx.currentPage,
                    perpage: ctx.perPage,
                    sortby: ctx.sortBy,
                    sortdesc: ctx.sortDesc,
                    filter: ctx.filter,
                    filterby: this.filterBy,
                    statusspt: this.statusspt,
                    perangkatpd: this.perangkatpd,
                    bulan: this.perangkat.bulan,
                    tahun: this.perangkat.tahun,
                }
            });
            return promise
                .then(response => {
                    const items = response.data.data;
                    // Data Pagination
                    // configPagination(response.data.meta);
                    this.totalRows = response.data.meta.total;
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.finish();
                    this.isBusy = false;
                    // console.log(items)
                    return items;
                })
                .catch(error => {
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.fail();
                    this.isBusy = false;
                    if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll();
                    }
                    // Returning an empty array, allows table to correctly handle
                    // internal busy state in case of error
                    return [];
                });
        },
        reload() {
            // with id="masTable" in tag b-table
            // this.$root.$emit('bv::refresh::table', 'masTable')
            // with property ref="masTable" in tag b-table
            this.$refs.masTable.refresh();
        },
        changeFilterBy() {
            if (this.filter) {
                this.$refs.masTable.refresh();
            }
        },
        swalNotification(swalType, id) {
            var btnClass = swalType == "error" ? "danger" : swalType;
            btnClass = swalType == "question" ? "primary" : btnClass;
            this.$swal({
                title: "Anda yakin ?",
                text: "Anda tidak akan bisa mengembalikan data!",
                type: swalType,
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonText: "Hapus",
                cancelButtonText: "Cancel",
                confirmButtonClass: "btn m-r-5 btn-" + btnClass + "",
                cancelButtonClass: "btn btn-default"
            }).then(result => {
                if (result.isConfirmed) {
                    axios
                        .delete("/api/manajemen/tarif/" + id)
                        .then(response => {
                            this.$swal({
                                title: "Data Berhasil Dihapus!",
                                icon: "success",
                                type: "success",
                                showCloseButton: true,
                                showConfirmButton: false,
                                timer: 1800
                            });
                            this.reload();
                        });
                }
            });
        },
        editButton(index) {
            this.$router.push({ name: "EditRefRek1", params: { id: index } });
        },
        nextButton(id) {
            this.$router.push({ name: "NextStep1", params: { id: id } });
        },
        nextButtonTiket(id) {
            this.$router.push({ name: "NextStepOne", params: { id: id } });
        },

        showPdf(id, cond) {
            if (cond.selesai === 0) {
                this.$swal({
                    title: "Anda Belum Menyelesaikan Pembuatan SPTRD",
                    text:
                        "Silahkan tekan tombol 'next' pada tabel untuk melanjutkan",
                    icon: "error",
                    confirmButtonText: "OKE",
                    confirmButtonClass: "btn btn-primary",
                    showCancelButton: false,
                    timer: 5000
                });
            } else {
                switch (cond.nama) {
                    case "skr":
                        this.getPdf(id);
                        this.namaDokumen = "Detail SKRD Provinsi";
                        break;
                    case "spt":
                        this.getPdfRincian(id);
                        this.namaDokumen = "Detail SPTRD Provinsi";
                        break;
                    default:
                        this.pdfUrl = "";
                }
            }
        },
        // close modal
        closeModal() {
            this.$root.$emit("bv::hide::modal", "modal-1");
            this.showModalData = false;
            this.pdfUrl = null;
        },

        // batal modal
        batal(value) {
            this.isDisabled = false;
            this.keterangan = "";
            this.form.clear();
            this.form.reset();
            this.$root.$emit("bvv::show::modal", "modal-batal");
            this.showModalBatal = true;
            this.form.fill(value);
            this.form.npwrd = value.npwrd.nama;
            this.form.perangkat = value.perangkat_pd.nama;
            this.form.unit = value.unit_pd.nama;
            this.form.status_nama = value.status_spt.nama;
            this.form.linimasa = value.linimasa;
            this.form.id = value.id;
            if (value.status_spt_id == 5) {
                this.isDisabled = true;
            }
        },
        // view pdf
        getPdf(id) {
            this.showLoader = true;
            axios
                .get("/api/view-pdf/surat/skr/spt-" + id, {
                    responseType: "arraybuffer"
                })
                .then(response => {
                    this.showLoader = false;
                    this.pdfUrl = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/pdf" })
                    );
                    window.open(this.pdfUrl, "_blank");
                })
                .catch(error => {
                    this.showLoader = false;
                    if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll();
                    }
                });
        },

        getPdfRincian(id) {
            this.showLoader = true;
            axios
                .get("/api/view-pdf/surat/spt/detail/spt-" + id, {
                    responseType: "arraybuffer"
                })
                .then(response => {
                    this.showLoader = false;
                    this.pdfUrl = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/pdf" })
                    );
                    window.open(this.pdfUrl, "_blank");
                })
                .catch(error => {
                    this.showLoader = false;
                    if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll();
                    }
                });
        },
        showLini(value) {
            this.$root.$emit("bv::show::modal", "modalLiniMasa");
            this.showLiniMasa = true;
            this.linimasa = value;
        },
        filterStatus(value) {
            this.statusspt = value;
            this.reload();
        },
        pembatalan(id) {
            this.isLoading = true;
            axios
                .put("/api/transaksi/spt/" + id + "/dibatalkan", {
                    linimasa: this.keterangan
                })
                .then(response => {
                    this.$swal({
                        title: "SPTRD BERHASIL DIBATALKAN",
                        text:
                            "SPTRD telah dibatalkan, silahkan lihat kembali data pada list SPTRD.",
                        icon: "success",
                        showCloseButton: true,
                        timer: 5000
                    });
                    this.isLoading = false;
                    this.isDisabled = true;
                    this.form.status_nama = response.data.data.status_spt.nama;
                    this.form.status_spt.warna =
                        response.data.data.status_spt.warna;
                    this.form.linimasa = response.data.data.linimasa;
                    this.reload();
                })
                .catch(error => {
                    this.isLoading = false;
                    if (error.response.status == 403) {
                        this.$swal({
                            title: "Terjadi Masalah",
                            text: error.response.data.status.message,
                            icon: "error",
                            showCloseButton: true,
                            timer: 5000
                        });
                    }
                });
        },
        hapusKet() {
            this.form.keterangan = "";
        },
        getTahun() {
            const now = new Date();
            this.tahunOptions.push(now.getFullYear() - 1);
            this.tahunOptions.push(now.getFullYear());
        },
        inputPerangkat() {
            if (!this.perangkat.tahun) {
                this.perangkat.tahun = new Date().getFullYear()
            }
            this.reload()
        },
        validasi(id) {
            this.showLoader = true;
            axios
                .get("/api/transaksi/spt/" + id + "/bukti-setor")
                .then(response => {
                    this.showLoader = false;
                    this.$swal({
                        icon: "success",
                        title: "Berhasil.",
                        type: "success",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        confirmButtonClass: "btn btn-primary",
                        confirmButtonText: "OKE",
                        showCloseButton: true,
                        timer: 6000
                    });
                    this.reload();
                })
                .catch(error => {
                    this.showLoader = false;
                    this.$swal({
                        icon: "error",
                        title: "Terjadi Masalah.",
                        text: error.response.data.status.message,
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        confirmButtonClass: "btn btn-primary",
                        confirmButtonText: "OKE",
                        showCloseButton: true,
                        timer: 6000
                    });
                });
        }
    }
};
</script>
